// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("./../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-jsx": () => import("./../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-templates-about-page-jsx": () => import("./../src/templates/aboutPage.jsx" /* webpackChunkName: "component---src-templates-about-page-jsx" */),
  "component---src-templates-contact-page-jsx": () => import("./../src/templates/contactPage.jsx" /* webpackChunkName: "component---src-templates-contact-page-jsx" */),
  "component---src-templates-cookie-policy-jsx": () => import("./../src/templates/cookiePolicy.jsx" /* webpackChunkName: "component---src-templates-cookie-policy-jsx" */),
  "component---src-templates-faq-page-jsx": () => import("./../src/templates/faqPage.jsx" /* webpackChunkName: "component---src-templates-faq-page-jsx" */),
  "component---src-templates-form-page-jsx": () => import("./../src/templates/formPage.jsx" /* webpackChunkName: "component---src-templates-form-page-jsx" */),
  "component---src-templates-home-page-jsx": () => import("./../src/templates/homePage.jsx" /* webpackChunkName: "component---src-templates-home-page-jsx" */),
  "component---src-templates-need-more-page-jsx": () => import("./../src/templates/needMorePage.jsx" /* webpackChunkName: "component---src-templates-need-more-page-jsx" */),
  "component---src-templates-portfolio-page-jsx": () => import("./../src/templates/portfolioPage.jsx" /* webpackChunkName: "component---src-templates-portfolio-page-jsx" */),
  "component---src-templates-project-page-jsx": () => import("./../src/templates/projectPage.jsx" /* webpackChunkName: "component---src-templates-project-page-jsx" */),
  "component---src-templates-thank-you-page-jsx": () => import("./../src/templates/thankYouPage.jsx" /* webpackChunkName: "component---src-templates-thank-you-page-jsx" */)
}

